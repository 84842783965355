<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-12">{{ taxGroup.name }}</span>
    </div>

    <div>
      <dl class="row">
        <dt class="col-sm-2">{{ $t("TAX_GROUPS.ACTIVE") }}</dt>
        <dd class="col-sm-10">
          <icon-check :checked="!!taxGroup.active" />
          {{ $t("TAX_GROUPS.ACTIVE") }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-2">{{ $t("COMMON.NAME") }}</dt>
        <dd class="col-sm-10">
          {{ taxGroup.name }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-2">{{ $t("COMMON.EXCERPT") }}</dt>
        <dd class="col-sm-10">
          <div v-html="taxGroup.excerpt"></div>
        </dd>
      </dl>
      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <dt class="col-sm-2">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-10">
          <organization :organization="taxGroup.organization" />
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-2">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-10" v-if="taxGroup.created_at">
          {{ $formatDate(taxGroup.created_at) }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-2">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-10" v-if="taxGroup.updated_at">
          {{ $formatDate(taxGroup.updated_at) }}
        </dd>
      </dl>
    </div>

    <div style="" class="col-12 text-center mb-2 mt-5">
      <h2>{{ $t("TAXES.TAX_TYPE_SALES") }}</h2>
    </div>

    <div class="row">
      <el-table
        class="table-responsive align-items-center table-flush"
        header-row-class-name="thead-light"
        :data="taxGroup.taxes.filter((item) => item.tax_type == TAX_TYPE_SALES)"
      >
        <el-table-column :label="$t('TAXES.ACTIVE')" prop="active">
          <template v-slot="{ row }">
            <icon-check :checked="!!row.active" />
            {{ $t("TAXES.ACTIVE") }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('COMMON.NAME')"
          prop="name"
          sortable="custom"
        />
        <el-table-column
          :label="$t('TAXES.LABEL')"
          prop="label"
          sortable="custom"
        />
        <el-table-column
          :label="$t('TAXES.VALUE')"
          prop="value"
          sortable="custom"
        />
        <el-table-column min-width="50px" align="center">
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip
              :content="$t('COMMON.VIEW')"
              placement="top"
              v-if="$currentUserCan($permissions.PERM_VIEW_TAXES)"
            >
              <a
                type="text"
                @click="viewTax(row)"
                class="table-action"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-eye"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
    </div>

    <div style="" class="col-12 text-center mb-2 mt-5">
      <h2>{{ $t("TAXES.TAX_TYPE_PURCHASES") }}</h2>
    </div>
    <div class="row">
      <el-table
        class="table-responsive align-items-center table-flush"
        header-row-class-name="thead-light"
        :data="
          taxGroup.taxes.filter((item) => item.tax_type == TAX_TYPE_PURCHASES)
        "
      >
        <el-table-column :label="$t('TAXES.ACTIVE')" prop="active">
          <template v-slot="{ row }">
            <icon-check :checked="!!row.active" />
            {{ $t("TAXES.ACTIVE") }}
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('COMMON.NAME')"
          prop="name"
          sortable="custom"
        />
        <el-table-column
          :label="$t('TAXES.LABEL')"
          prop="label"
          sortable="custom"
        />
        <el-table-column
          :label="$t('TAXES.VALUE')"
          prop="value"
          sortable="custom"
        />
        <el-table-column min-width="50px" align="center">
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip
              :content="$t('COMMON.VIEW')"
              placement="top"
              v-if="$currentUserCan($permissions.PERM_VIEW_TAXES)"
            >
              <a
                type="text"
                @click="viewTax(row)"
                class="table-action"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-eye"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn } from "element-ui";
import { TAX_TYPE_SALES, TAX_TYPE_PURCHASES } from "@/constants/taxes";
import IconCheck from "@/components/IconCheck.vue";

export default {
  name: "tax-group-view-global",

  components: {
    IconCheck,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  props: ["taxGroup"],

  data() {
    return {
      TAX_TYPE_SALES: TAX_TYPE_SALES,
      TAX_TYPE_PURCHASES: TAX_TYPE_PURCHASES,
    };
  },

  computed: {},

  created() {},

  methods: {
    viewTax(tax) {
      this.$router.push({
        name: "View Tax",
        params: { id: tax.id },
      });
    },
  },

  mounted() {},

  watch: {
    taxGroup(taxGroup) {},
  },
};
</script>
